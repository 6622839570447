const useEncrypt = () => {
    const encrypt = (value: string) => window.btoa(value);
    const decrypt = (value?: string | null) => value ? window.atob(value) : undefined;

    return {
        encrypt,
        decrypt
    }
}

export default useEncrypt;