import {useMemo} from "react";
import Logo from "@/components/elements/Logo";

const InlineLoader = () => {
    const text = useMemo(() => {
        const texts = [
            'Warming the ice...',
            'Melting the ice...',
            'Heating up...',
            'Warming up...',
            'Breaking the ice...',
        ];

        return texts[Math.floor(Math.random() * texts.length)];
    }, []);

    return (
        <p
            className="flex items-center gap-2 text-sm italic"
        >
            <Logo className="size-4 opacity-10 animate-pulse"/>
            <span>{text}</span>
        </p>
    )
}

export default InlineLoader