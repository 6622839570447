import React from "react";
import {Skeleton} from "@/components/ui/skeleton";


const ContactHeaderSkeleton = () => {
    return (
        <div className="p-8 w-full">
            <div className="flex gap-8">
                <Skeleton
                    className="size-24 rounded-full"
                />

                <div className="flex-grow h-full flex flex-col">
                    <div className="text-2xl font-bold mb-2">
                        <Skeleton className="w-40 h-8"/>
                    </div>
                    <div
                        className="text-sm opacity-50"
                    >
                        <Skeleton className="w-60 h-4"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactHeaderSkeleton;