import ConversationsList from "@/modules/conversations/components/ConversationsList";
import React, {useEffect, useState} from "react";
import Conversation from "@/modules/conversations/Conversation";
import {useParams} from "react-router";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useTranslation} from "react-i18next";

const Conversations = () => {
    const {conversationId} = useParams();
    const [showConversation, setShowConversation] = useState(true);

    useEffect(() => {
        setShowConversation(false);
        setTimeout(() => {
            setShowConversation(true);
        }, 50);
    }, [conversationId]);

    const {t} = useTranslation();

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('conversations.page_title'));
    }, [t, setCrumbs, setTitle]);

    return (
        <>
            <div className="flex gap-4 h-[calc(100vh-4rem)]">
                <ConversationsList className="flex-shrink-0 w-28"/>
                {showConversation && <Conversation className="flex-grow flex-shrink"/>}
            </div>
        </>
    )
}

export default Conversations;