import VideoPlayer from "@/components/elements/VideoPlayer";
import {Dialog, DialogContent, DialogTrigger} from "@/components/ui/dialog";

type MessageMediaProps = {
    message: any;
    className?: string;
}

const MessageMedia = ({message, className}: MessageMediaProps) => {
    const renderContent = message.renderContent ?? [];

    return (
        <>
            {renderContent.map((content: any, index: number) => (
                <div key={index} className={className}>
                    {content.video && <MessageVideo content={content.video}/>}
                    {content.vectorImage && <MessageImage content={content.vectorImage}/>}
                    {content.audio && <MessageAudio content={content.audio}/>}
                </div>
            ))}
        </>
    )
}
const MessageAudio = ({content}: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    content: any
}) => {
    const audioUrl = content.url ?? '';

    return (
        <>
            <audio controls>
                <source src={audioUrl} type="audio/mp3"/>
            </audio>
        </>
    )
}

const MessageImage = ({content}: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    content: any
}) => {
    const thumbnail = content.rootUrl ?? '';

    return (
        <Dialog>
            <DialogTrigger>
                <img
                    src={thumbnail}
                    alt="thumbnail"
                    loading="lazy"
                    className="h-[220px] w-auto rounded-xs border"
                />
            </DialogTrigger>
            <DialogContent
                className="w-3/4 max-w-3/4 h-3/4 max-h-3/4"
            >
                <div
                    className="h-full w-full flex justify-center items-center"
                >
                    <img
                        src={thumbnail}
                        alt="thumbnail"
                        loading="lazy"
                        className="block h-[calc(100vh*.7)] w-[calc(100vw*.7)] object-contain"
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

const MessageVideo = ({content}: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    content: any
}) => {

    const thumbnail = content.thumbnail?.artifacts?.[0]?.fileIdentifyingUrlPathSegment ?? '';
    const videoUrl = content.progressiveStreams?.[0]?.streamingLocations?.[0]?.url ?? '';

    return (
        <VideoPlayer
            videoUrl={{mp4: videoUrl}}
            posterUrl={thumbnail}
            ratio={'16:9'}
            className="h-[300px] rounded-[inherit]"
        />
    )
}

export default MessageMedia;