import {Button} from "@/components/ui/button";
import React, {useMemo, useState} from "react";
import useUser from "@/hooks/useUser";
import {useAddFromBridgeMutation} from "@/api/contact";
import useFullLists from "@/hooks/useFullLists";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import Loader from "@/components/elements/Loader";

const NoContactPanel = ({messages}: any) => {
    const {user} = useUser();

    const {content: lists} = useFullLists();

    const [selectedListUuid, setSelectedListUuid] = useState<null | string>(null);

    const [addFromBridge] = useAddFromBridgeMutation();

    const [added, setAdded] = useState(false);

    const contactUrls = useMemo(() => {
        const urls = messages
            .filter((message: any) => message?.sender?.backendUrn !== user?.linkedin_object_id)
            .map((message: any) => message?.sender?.participantType?.member?.profileUrl)

        return urls.filter((url: string, index: any, self: any) => self.indexOf(url) === index && !url?.includes(user?.linkedin_id ?? '')) ?? [];
    }, [messages, user]);

    const contactUrl = contactUrls[0] ?? null;

    const handleAddContact = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        addFromBridge({
            url: contactUrl,
            listUuid: selectedListUuid ?? undefined
        }).then();

        setAdded(true);
    }

    if (added) return <>
        <h2 className="text-lg font-bold font-display">
            This person is being added to your contacts.
        </h2>

        <p className="text-sm opacity-50 w-3/4">
            The conversation will be refreshed once the process is completed.
        </p>

        <Loader
            className="h-16"
        />
    </>

    return (<>
            <h2 className="text-lg font-bold font-display">
                This person is not in a contact list.
            </h2>

            <p className="text-sm opacity-50 w-3/4">
                The advanced features of Warmr require you to add this person to your contacts.
            </p>

            <p className="text-sm opacity-50 w-3/4">
                Once you have added this person to your contacts, you will be able to send icebreakers, score your
                relationship and use the pipelines to get the most out of Warmr.
            </p>

            <div
                className="flex gap-4"
            >
                {lists.length > 1 && <Select
                    onValueChange={(value) => {
                        setSelectedListUuid(value);
                    }}
                >
                    <SelectTrigger className="w-40 h-12">
                        <SelectValue placeholder="Select a list"/>
                    </SelectTrigger>
                    <SelectContent>
                        {lists?.map((list) => (
                            <SelectItem
                                key={list.uuid}
                                value={list.uuid}
                            >
                                {list.icon && <span className="mr-2">{list.icon}</span>}
                                {list.name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>}

                <Button
                    disabled={!contactUrl}
                    onClick={handleAddContact}
                    variant="white"
                    className="h-12"
                >
                    Add to contact list
                </Button>
            </div>
        </>
    )
}

export default NoContactPanel;