import {useGetUserExtensionVersionQuery} from "@/api/user";
import {cn} from "@/lib/utils";
import dayjs from "dayjs";
import {CircleAlert} from "lucide-react";
import {Button} from "@/components/ui/button";
import {Link} from "react-router-dom";

const UserBridgeVersionAlert = () => {
    const {data: extensionVersion, isLoading} = useGetUserExtensionVersionQuery();

    if(isLoading) return null;

    if(extensionVersion?.version&&extensionVersion.updatedAt&&dayjs(extensionVersion.updatedAt).diff(dayjs(), 'days') < 5) return null;

    return (
        <div className={cn([
            'bg-destructive',
            'text-destructive-foreground',
            'rounded-md',
            'p-4',
            'mb-2',
            'flex flex-col gap-2',
        ])}>
            <CircleAlert className="size-8" />
            <p><strong>Your browser extension is out of date.</strong> Please update your browser extension to the latest version.</p>
            <Button className="!bg-background" asChild>
                <Link to={"/static/extension"}>
                    Install latest version
                </Link>
            </Button>
        </div>
    );

    return null;
}

export default UserBridgeVersionAlert;