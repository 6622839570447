import {useParams} from "react-router";
import {useEffect, useState} from "react";

const EoySummaryData = () => {
    const {uuid}  = useParams()
    const [data, setData] = useState<any>(null);

    const status = data?.data?.status;
    const profileData = data?.data?.data?.profileData;
    const mood = data?.data?.data?.mood;
    const topSubjects = data?.data?.data?.topSubjects?.split(',')??[];
    const preferredEmojis = data?.data?.data?.preferredEmojis?.split(',')??[];
    const topQuotation = data?.data?.data?.topQuotation;
    const target = data?.data?.data?.target;

    const handleLoadData = async () => {
        const backendHost = process.env.REACT_APP_API_HOST
        const url = `${backendHost}/tools/eoy/${uuid}`

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())

            setData(response)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleLoadData().then()

        const interval = setInterval(() => {
            handleLoadData().then()
        }, 10000)

        return () => clearInterval(interval)
    }, []);

    return <div className="p-8 flex flex-col gap-8 h-screen">
        <h1 className="text-4xl font-extrabold font-display">
            Your 2024 LinkedIn Summary
        </h1>

        <div className="flex flex-col gap-4">
            {status==='pending'||status==='planned' && <h2 className="text-xl font-bold">
                <p>Due to a high volume of requests, your data is not ready yet.</p>
                <p>No worries if you leave this page, you will receive an email when your data is ready.</p>
            </h2>}

            {status==='running' && <h2 className="text-xl font-bold">
                Your data is being processed. Please wait a few minutes.
            </h2>}

            <div className="flex gap-4 item-center">
                {profileData?.profilePictureUrl && <img src={profileData?.profilePictureUrl} alt="profile picture" className="rounded-full size-40 aspect-square object-cover"/>}

                <div>
                    {profileData?.firstName && <p className="text-xl font-bold">{profileData?.firstName} {profileData?.lastName}</p>}
                    <p className="flex gap-4">{preferredEmojis.map((emoji:string)=><span>{emoji}</span>)}</p>
                    <p className="italic bold font-xl">{topQuotation}</p>
                </div>
            </div>

            {profileData?.firstName && <p className="text-xl font-bold">Hey, <strong>{profileData?.firstName}</strong> !</p>}

            {mood &&
                <p className="text-xl font-bold">
                    This year was full of <strong className="underline lowercase">{mood}</strong> posts.
                </p>
            }

            {topSubjects&&topSubjects.length>1 && <p className="text-xl font-bold">
                You posted about {topSubjects.map((subject:string)=><><strong>{subject}</strong>, </>)}...
            </p>}

            {target && <p className="text-xl font-bold">Your posts were read by <strong>{target}</strong></p>}
        </div>
    </div>
}

export default EoySummaryData