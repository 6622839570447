import {BrowserRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import useDarkMode from "@/hooks/useDarkMode";
import AppRouter from "@/Router";
import UserSignInModal from "@/modules/login/UserSignInModal";
import {Toaster} from "@/components/ui/sonner";
import {clsx} from "clsx";
import UserMenu from "@/components/layout/header/UserMenu";
import Navigation from "@/components/layout/header/Navigation";
import HeaderContainer from "@/components/layout/header/HeaderContainer";
import {IconPicker} from "@/components/data/ObjectIcon";
import React from "react";
import {UiAlert} from "@/components/elements/UiAlert";
import {ContactSheet} from "@/modules/contacts/components/ContactSheet";
import UserSubscriptionPanel from "@/modules/subscription/UserSubscriptionPanel";
import Logo from "@/components/elements/Logo";
import Celebration from "@/components/elements/Celebration";
import {useNotificationsStore} from "@/modules/user/components/NotificationsPanel";
import useUser from "./hooks/useUser";
import {cn} from "@/lib/utils";
import UserBridgeVersionAlert from "@/modules/user/components/UserBridgeVersionAlert";
import OnboardingTour from "@/components/elements/OnboardingTour";

function App() {
    useDarkMode();

    const productName = process.env.REACT_APP_PRODUCT_NAME;
    const {i18n} = useTranslation();
    const currentLanguage = i18n.language;

    const {unreadCount} = useNotificationsStore((state) => state) as any;

    const {user} = useUser();

    return (
        <div className={"App"} vaul-drawer-wrapper={''}>
            <Helmet titleTemplate={(unreadCount ? '(' + unreadCount + ') ' : '') + "%s — " + productName}>
                <html lang={currentLanguage}/>
            </Helmet>

            <BrowserRouter>
                <div className={clsx([
                    "flex",
                    "min-h-screen",
                    "relative",
                    "w-full"
                ])}>
                    <div
                        className={clsx([
                            "flex",
                            "flex-shrink-0",
                            "flex-col",
                            "w-[18em]",

                            "sticky",
                            "top-0",

                            // 'm-[.5em]',
                            // 'top-[.5em]',
                            // 'h-[calc(100vh-1.33em)]',

                            'h-screen',

                            'dark:bg-muted',
                            "dark:text-muted-foreground",

                            // 'rounded-r-lg',

                            'bg-gray',
                            'text-gray-foreground',

                            'p-[.5em]',

                            'max-md:fixed',
                            'max-md:z-[1000]',
                            'max-md:bg-muted',
                            'max-md:top-0',
                            'max-md:left-0',
                            'max-md:translate-x-[-16rem]',
                            'max-md:hover:translate-x-0',
                            'max-md:hover:shadow-xl',
                            'max-md:transition-transform',
                            'max-md:after:fixed',
                            'max-md:after:bottom-24',
                            'max-md:after:-right-12',
                            'max-md:after:size-12',
                            'max-md:after:block',
                            'max-md:after:bg-background',
                            'max-md:after:shadow-sm',
                            'max-md:after:border',
                            'max-md:after:border-l-0',
                            'max-md:after:rounded-tr-lg',
                            'max-md:after:rounded-br-lg',
                            'max-md:after:content-[">"]',
                            'max-md:after:text-foreground/50',
                            'max-md:after:font-bold',
                            'max-md:after:flex',
                            'max-md:after:items-center',
                            'max-md:after:justify-center',
                            'max-md:hover:after:opacity-0',
                        ], {
                            'hidden': !user
                        })}
                    >
                        <div className="flex-1">
                            <Navigation/>
                        </div>

                        <UserBridgeVersionAlert/>
                        <UserMenu/>
                    </div>
                    <div
                        className={cn([
                            "flex",
                            "flex-col",
                            "flex-1",
                            "min-h-screen",
                            "w-[calc(100%-18rem)]",
                        ], {
                            'w-full': !user
                        })}
                    >
                        {user && <HeaderContainer isMain/>}
                        <div
                            className={clsx([
                                "flex",
                                "flex-col",
                                "flex-1",
                                "overflow-x-hidden",
                            ])}
                        >
                            <AppRouter/>
                        </div>
                    </div>
                    <ContactSheet/>
                    <UserSignInModal/>
                    <UserSubscriptionPanel/>
                    <IconPicker/>
                    <Toaster richColors/>
                    <UiAlert/>
                    <Celebration/>
                    <OnboardingTour/>
                </div>
            </BrowserRouter>

            <div
                className="lg:hidden fixed top-0 left-0 p-8 text-center w-full h-full bg-background flex flex-col gap-4 items-center justify-center z-[9999999999999999]">
                <Logo className="w-12 h-auto"/>
                <p className="font-display text-xl">
                    Oooops! Warmr is not supported on small screens (yet).
                </p>
            </div>
        </div>
    );
}

export default App;