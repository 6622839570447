import DateRenderer from "@/components/elements/DateRenderer";
import useUser from "@/hooks/useUser";
import {cn} from "@/lib/utils";
import MessageMedia from "@/modules/conversations/components/MessageMedia";
import {Contact} from "@/api/contact.types";
import dayjs from "dayjs";
import MessageReactions from "@/modules/conversations/components/MessageReactions";
import {ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger} from "@/components/ui/context-menu";
import {useDeleteMessageMutation} from "@/api/conversation";
import {useState} from "react";

type ConversationMessageProps = {
    message: any;
    showInfo?: boolean;
    previousMessage?: any;
    nextMessage?: any;
    contact?: Contact;
}

const ConversationMessage = ({message, showInfo, previousMessage, nextMessage, contact}: ConversationMessageProps) => {
    const {user} = useUser();

    const [deleteMessage] = useDeleteMessageMutation();

    const [hasDeleted, setHasDeleted] = useState(false);

    const handleDeleteMessage = () => {
        deleteMessage({messageUrn: message.entityUrn}).then();
        setHasDeleted(true);
    }

    const sender = message.sender?.participantType?.member?.firstName?.text ?? '';
    const isSentFromUser = message.fromUser || message.sender?.backendUrn === user?.linkedin_object_id;

    const previousMessageIsFromUser = previousMessage && (previousMessage?.fromUser || previousMessage?.sender?.backendUrn === user?.linkedin_object_id);
    const nextMessageIsFromUser = nextMessage && (nextMessage?.fromUser || nextMessage?.sender?.backendUrn === user?.linkedin_object_id);

    const previousMessageFromSameSender = dayjs(previousMessage?.deliveredDate) > dayjs(message.deliveredDate).subtract(5, 'minute') && ((isSentFromUser && previousMessageIsFromUser) || (!isSentFromUser && !previousMessageIsFromUser));
    const nextMessageFromSameSender = dayjs(nextMessage?.deliveredDate) > dayjs(message.deliveredDate).subtract(5, 'minute') && ((isSentFromUser && nextMessageIsFromUser) || (!isSentFromUser && !nextMessageIsFromUser));

    const messageText = message.body.text;

    const isDeleted = message.messageBodyRenderFormat === 'RECALLED' || hasDeleted;

    return (
        <div className={cn([
            'flex flex-col gap-1 group'
        ], {
            'items-end': isSentFromUser,
            'items-start': !isSentFromUser,
            'py-4': !previousMessageFromSameSender && !nextMessageFromSameSender,
        })}>
            {isDeleted ? <div
                className={cn([
                    'py-2',
                    'px-4',
                    'rounded-sm',
                    'max-w-[80%]'
                ], [
                    'uppercase opacity-50',
                ], 'bg-background border border-muted')}
            >
                Deleted message
            </div> : <>
                <ContextMenu>
                    <ContextMenuTrigger asChild>
                        <div
                            className={!messageText ? '' : cn([
                                'py-2',
                                'px-4',
                                'rounded-sm',
                                'max-w-[80%]',
                            ], {
                                'bg-tertiary text-tertiary-foreground': isSentFromUser,
                                'bg-muted text-muted-foreground': !isSentFromUser,
                                'bg-background border border-muted': !contact && !isSentFromUser,
                                'bg-muted border border-muted': !contact && isSentFromUser,
                            })}
                        >
                            <MessageMedia message={message} className="rounded-sm py-2"/>

                            <p
                                className={cn([
                                    'whitespace-pre-wrap',
                                    'break-words',
                                ])}
                            >
                                {messageText}
                            </p>
                        </div>
                    </ContextMenuTrigger>
                    <ContextMenuContent>
                        <ContextMenuItem
                            onClick={handleDeleteMessage}
                            disabled={!isSentFromUser}
                        >
                            Delete
                        </ContextMenuItem>
                    </ContextMenuContent>
                </ContextMenu>


                <MessageReactions
                    message={message}
                    className="-mt-3 px-4 mb-3"
                />
            </>}

            {showInfo &&
                <p
                    className={cn([
                        'text-xs',
                        'opacity-50',
                        'flex',
                        'items-center',
                        'gap-2',
                    ])}
                >
                    {isSentFromUser ? 'You' : sender} — <DateRenderer date={message.deliveredDate}/>
                </p>
            }
        </div>
    )
}

export default ConversationMessage;