import useFullContactsList from "@/hooks/useFullContactsList";
import React, {useState} from "react";
import {Contact} from "@/api/contact.types";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import NewMessage from "@/modules/conversations/components/NewMessage";

const NewConversation = () => {

    const {content: contacts} = useFullContactsList();
    const [selectedContactUuid, setSelectedContactUuid] = useState<Contact['uuid'] | null>(null);
    const [initialMessage, setInitialMessage] = useState<string>('');
    const selectedContact = contacts?.find((contact) => contact.uuid === selectedContactUuid);

    return (
        <div className="flex flex-col gap-2">
            <Select
                onValueChange={(value) => {
                    setSelectedContactUuid(value);
                    setInitialMessage('');
                }}
                disabled={!contacts?.length}
            >
                <SelectTrigger className="w-full">
                    <SelectValue placeholder="Contact"/>
                </SelectTrigger>
                <SelectContent>
                    {contacts?.map((contact) => (
                        <SelectItem key={contact.uuid} value={contact.uuid}>
                            <div className="flex items-center gap-2">
                                <ObjectAvatar
                                    object={contact}
                                />

                                {contact.display_name}
                            </div>
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>

            {selectedContactUuid && (
                <div className="flex flex-col gap-2 pt-4">
                    <h2 className="text-lg font-bold">
                        Initial message
                    </h2>

                    <NewMessage
                        onSending={(message) => setInitialMessage(message ?? '')}
                        contact={selectedContact}
                        variant="vertical-simple"
                        scheme="white"
                    />
                </div>
            )}
        </div>
    )
}

export default NewConversation;