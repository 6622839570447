import {useGetUserQueueQuery} from "@/api/user";
import {useEffect, useMemo} from "react";
import {BellIcon} from "lucide-react";
import {badgeVariants} from "@/components/ui/badge";
import {clsx} from "clsx";
import {Sheet, SheetContent, SheetTrigger} from "@/components/ui/sheet";
import {useTranslation} from "react-i18next";
import NotificationCard from "@/modules/user/components/NotificationCard";
import dayjs from "dayjs";
import useSocket from "@/hooks/useSocket";
import {toast} from "sonner";
import {QueueTask} from "@/api/user.types";
import {useGetNotificationsQuery, usePatchUserNotificationMutation} from "@/api/notification";
import type {Notification} from "@/api/notification.types";
import {create} from "zustand";
import {Button} from "@/components/ui/button";

export const useNotificationsStore = create((set) => ({
    unreadCount: 0,
    setUnreadCount: (unreadCount: number) => set({unreadCount}),
}));

const NotificationsPanel = () => {
    const {t} = useTranslation();

    const {data: queue, isLoading: queueLoading, refetch: refetchQueue} = useGetUserQueueQuery();
    const {data:notifications, isLoading:notificationsLoading, refetch:refetchNotifications} = useGetNotificationsQuery({
        page:1
    }, {
        refetchOnFocus: true,
        refetchOnReconnect: true,
    });

    const [patchNotification] = usePatchUserNotificationMutation();

    const {lastEventData: lastQueueEventData} = useSocket('queue', true)
    const {lastEventData: lastNotificationEventData} = useSocket('notification', true)

    useEffect(() => {
        refetchQueue()
        setTimeout(() => {
            refetchQueue()
        }, 5000)
    }, [lastQueueEventData, refetchQueue])

    useEffect(() => {
        refetchNotifications()
        setTimeout(() => {
            refetchNotifications()
        }, 5000)
    }, [lastNotificationEventData, refetchNotifications])

    const hasNotCompletedTasks = queue && queue.length && queue.some((queueTask) => queueTask.status === 'pending' || queueTask.status === 'running');

    const isLoading =( queueLoading && !queue) || (notificationsLoading && !notifications);

    // Notification toast
    useEffect(() => {
        if (lastNotificationEventData && lastNotificationEventData.type === 'task') {
            if(lastNotificationEventData.objectType === 'queue') {
                const task = lastNotificationEventData.object as QueueTask;

                if (task.status === 'failed') {
                    toast.error(t('notifications.titles.' + task.action + '_' + task.status, task), {
                        description: t('notifications.messages.' + task.action + '_' + task.status, task),
                    })
                } else if (task.status === 'completed') {
                    toast.success(t('notifications.titles.' + task.action + '_' + task.status, task), {
                        description: t('notifications.messages.' + task.action + '_' + task.status, task),
                    })
                } else {
                    toast(t('notifications.titles.' + task.action + '_' + task.status, task), {
                        description: t('notifications.messages.' + task.action + '_' + task.status, task),
                    })
                }

                // Use browser notification
                new window.Notification(t('notifications.titles.' + task.action + '_' + task.status, task))
            }

            if(lastNotificationEventData.objectType === 'notification') {
                const notification = lastNotificationEventData.object as Notification;

                toast(notification.title, {
                    description: notification.message,
                })

                // Use browser notification
                new window.Notification(notification.title, {
                    body: notification.message,
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastNotificationEventData])

    const mixedData = useMemo(()=>{
        if(!queue||!notifications) return null;
        const output: {
            date: string;
            type: 'queue'|'notification';
            object: QueueTask|Notification
        }[] = [];

        for (const notification of notifications?.data ?? []) {
            output.push({
                date: notification.createdAt,
                type: 'notification',
                object: notification
            })
        }

        for (const queueTask of queue) {
            output.push({
                date: queueTask.updatedAt,
                type: 'queue',
                object: queueTask
            })
        }

        return output.sort((a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1) ?? [];
    }, [queue, notifications]);

    const {unreadCount, setUnreadCount} = useNotificationsStore((state) => state) as any;

    useEffect(() => {
        const unreadItems = mixedData?.filter((item) => (item.object as Notification).readAt === null);
        const unreadCount = unreadItems?.length ?? 0;

        setUnreadCount(unreadCount);
    }, [mixedData, setUnreadCount]);

    const isDisabled = isLoading || (!unreadCount && !hasNotCompletedTasks);

    const markAllAsRead = () => {
        for (const item of notifications?.data??[]) {
            if(item.readAt===null) {
                patchNotification({
                    id: item.id,
                    read: true
                }).then();
            }
        }
    }

    return (
        <Sheet>
            <SheetTrigger>
                <div
                    className={clsx([
                        'relative',
                        'cursor-pointer',
                    ], {
                        'opacity-50': isDisabled
                    })}>
                    <BellIcon
                        className={clsx([
                            'size-5'
                        ], {
                            '-translate-x-2': !isDisabled
                        })}
                    />
                    {!isDisabled &&
                        <span
                            className={clsx(badgeVariants({variant: hasNotCompletedTasks ? "outline" : "destructive"}), [
                                '!size-5',
                                'absolute',
                                'top-0',
                                'right-0',
                                'translate-x-2',
                                '-translate-y-2',
                                "rounded-full",
                                "flex",
                                "items-center",
                                "justify-center",
                                "text-sm"
                            ])}>
                            {
                                !hasNotCompletedTasks && unreadCount > 0 && unreadCount
                            }
                    </span>
                    }
                </div>
            </SheetTrigger>
            <SheetContent
            className="w-[500px] !max-w-full bg-tertiary dark:bg-muted p-4 overflow-y-auto max-h-full"
            >
                {unreadCount > 0 && <div className="flex justify-end">
                    <Button variant="link" onClick={() => markAllAsRead()} className="text-sm text-tertiary-foreground">
                        Mark all as read
                    </Button>
                </div>}
                <div
                    className="mt-0 space-y-2"
                >
                    {(mixedData??[])
                        .map((queueTask) => {
                            if(queueTask.type === 'queue'){
                                return <NotificationCard key={'q' + (queueTask.object as QueueTask).id}
                                                         title={t('notifications.titles.' + (queueTask.object as QueueTask).action + '_' + (queueTask.object as QueueTask).status, (queueTask.object as QueueTask))}
                                                         message={t('notifications.messages.' + (queueTask.object as QueueTask).action + '_' + (queueTask.object as QueueTask).status, (queueTask.object as QueueTask))}
                                                         notifiedAt={(queueTask.object as QueueTask).updatedAt}
                                                         image={(queueTask.object as QueueTask).imageUrl}
                                                         link={(queueTask.object as QueueTask).contact ? '/contacts/' + ((queueTask.object as QueueTask)?.contact?.uuid) : ''}
                                                         type={(queueTask.object as QueueTask).status === 'running' ? "progress" : "default"}
                                                         read={(queueTask.object as QueueTask).status === 'completed' && dayjs((queueTask.object as QueueTask).updatedAt).subtract(3, 'minute').isBefore(dayjs())}
                                                         id={(queueTask.object as QueueTask).id}
                                />
                            }

                            if(queueTask.type === 'notification'){
                                return <NotificationCard key={'n' + (queueTask.object as Notification).id}
                                                         title={(queueTask.object as Notification).title}
                                                         message={(queueTask.object as Notification).message}
                                                         notifiedAt={(queueTask.object as Notification).createdAt}
                                                         image={(queueTask.object as Notification).params?.image}
                                                         type={(queueTask.object as Notification).params?.type ?? "default"}
                                                         read={!!(queueTask.object as Notification).readAt}
                                                         actions={(queueTask.object as Notification).actions}
                                                         markAsRead={true}
                                                         id={(queueTask.object as Notification).id}
                                />
                            }

                            return <></>
                        } )}
                </div>

                {!isLoading && !mixedData?.length &&
                    <div
                        className="text-center text-muted"
                    >
                        {t('notifications.empty')}
                    </div>
                }
            </SheetContent>
        </Sheet>
    )
}

export default NotificationsPanel;