import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {clsx} from "clsx";

import {BoldItalicUnderlineToggles, MDXEditor, toolbarPlugin} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import {cn} from "@/lib/utils";
import WysiwygMagic from "@/components/data/WysiwygMagic";

type WysiwygEditorProps = {
    outline?: boolean;
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    toolbar?: string[];
}

const WysiwygEditor = ({className, outline, value, onChange, disabled, placeholder, toolbar}: WysiwygEditorProps) => {
    const mdxEditor = useRef<any>(null);

    const handleChange = useCallback((value?: string) => {
        if (disabled) return;

        if(!value){
            value = mdxEditor.current?.getMarkdown();
        }

        // Remove HTML
        value = (value??'').replace(/<[^>]*>?/gm, '');

        onChange(value);
    }, [disabled, onChange]);

    useEffect(() => {
                mdxEditor.current?.setMarkdown(value);
                handleChange(value);
    }, []);

    const plugins = useMemo(() => {
        if (!toolbar) return [];

        return [toolbarPlugin({
            toolbarContents: () => {
                return <>
                    <BoldItalicUnderlineToggles/>
                </>
            },
        })]
    }, [toolbar])

    const [showToolbox, setShowToolbox] = useState(false);
    const [expandedToolbox, setExpandedToolbox] = useState(false);

    const [toolboxPosition, setToolboxPosition] = useState<{top: number, left: number}>({top: 0, left: 0})
    const editorRef = useRef<HTMLDivElement|null>(null)
    const [toolboxParagraphText, setToolboxParagraphText] = useState<string>('')
    const [showComponent, setShowComponent] = useState(true);

    useEffect(() => {
        if (!editorRef.current) return;

        const handleMouseMove = (e: any) => {
            if (!editorRef.current) return;

            const hoveredElements = document.elementsFromPoint(e.clientX, e.clientY)
            if (hoveredElements.length === 0) return;

            const paragraphElements = editorRef.current.querySelectorAll('p')
            paragraphElements.forEach((p: any) => p.classList.remove('bg-muted'))

            const hoveredParagraph = Array.from(paragraphElements).find((p: any) => p.contains(hoveredElements[0]))

            const hoveredParagraphRect = hoveredParagraph?.getBoundingClientRect()
            if (hoveredParagraphRect) {
                setToolboxPosition({
                    top: hoveredParagraphRect.top,
                    left: hoveredParagraphRect.left + hoveredParagraphRect.width
                })

                setShowToolbox(true)

                setToolboxParagraphText(hoveredParagraph?.innerText??'')

                hoveredParagraph?.classList.add('bg-muted')
            }
        }
        window.addEventListener('mousemove', handleMouseMove)
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
    }, [editorRef]);

    return (
        <div className={clsx(className, {
            "border": outline,
            "rounded-xs": outline
        })}
        ref={editorRef}
             onMouseLeave={() => {
                 setShowToolbox(false);
                 handleChange();
             }}
             onBlur={() => {
                 setShowToolbox(false);
                 handleChange();
             }}
             onKeyUp={(e) => {
                 handleChange();
             }}
        >
            <MDXEditor
                ref={mdxEditor}
                placeholder={placeholder}
                autoFocus={true}
                markdown={''}
                onChange={handleChange}
                plugins={plugins}
                contentEditableClassName={cn("!font-body !text-foreground !min-h-48 !space-y-3", {
                    '!p-3': outline,
                    '!p-0': !outline,
                })}
                //suppressHtmlProcessing={false}
                className={cn("!p-0", [
                    'flex',
                    'flex-col',
                    '[&>[role="toolbar"]]:!bg-transparent',
                    '[&>[role="toolbar"]]:!rounded-none',
                    '[&>[role="toolbar"]]:!order-24'
                ])}
            />

            <div
                style={{
                    opacity: (showToolbox&&toolboxParagraphText.length>3) ? 1 : 0,
                    position: 'fixed',
                    top: toolboxPosition.top,
                    left: toolboxPosition.left,
                    zIndex: 999999999
                }}
                className={cn("flex flex-col bg-background p-1 !h-12 !w-[320px] outline outline-gray-100 rounded-sm shadow-2xl transition-all duration-100 ease-in-out", {
                    '!h-[280px]': expandedToolbox
                })}
            >
                <WysiwygMagic
                    value={value}
                    selectedParagraph={toolboxParagraphText}
                    onAlternativeChoose={(markdown) => {
                        setShowComponent(false);
                        handleChange(markdown)
                        value = markdown
                        setShowToolbox(false)
                        setExpandedToolbox(false)
                        setTimeout(() => {
                            setShowComponent(true);
                        }, 100)
                    }}
                    onExpand={setExpandedToolbox}
                />
            </div>
        </div>
    )
}

export default WysiwygEditor;