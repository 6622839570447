import {Contact, ContactActivity, ContactNote, ContactQuery, ContactSearchQuery} from "./contact.types";
import {api} from "@/api/api";
import {ObjectQuery, PaginatedQuery, PaginatedResponse} from "@/api/api.types";

export const contactApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getContacts: builder.query<PaginatedResponse<Contact>, ContactSearchQuery & PaginatedQuery>({
            query: (queryString) => {
                const queryStringParameters = Object.keys(queryString)
                    // @ts-ignore
                    .map((key) => key + "=" + (queryString[key] ?? ''))
                    .join("&");

                return `contacts?${queryStringParameters}`;
            },
            providesTags: ["Contacts"],
        }),
        getContact: builder.query<Contact, ObjectQuery>({
            query: ({uuid, relations}) => `contacts/${uuid}${relations ? `?relations=${relations.join(',')}` : ''}`,
            providesTags: (result, error, {uuid}) => [{type: "Contacts", id: uuid}],
        }),
        getContactActivities: builder.query<PaginatedResponse<ContactActivity>, PaginatedQuery & ObjectQuery>({
            query: ({uuid, page}) => `contacts/${uuid}/activities?page=${page}`,
            providesTags: (result, error, {uuid}) => [{type: "Contacts", id: uuid}],
        }),
        autoFillContact: builder.mutation<Contact, ObjectQuery>({
            query: ({uuid}) => ({
                url: `contacts/${uuid}/fill-info`,
                method: "POST",
            }),
            invalidatesTags: (result, error, {uuid}) => [{type: "Contacts", id: uuid}],
        }),
        patchContact: builder.mutation<Contact, ObjectQuery & ContactQuery>({
            query: ({uuid, ...contact}) => ({
                url: `contacts/${uuid}`,
                method: "PATCH",
                body: contact,
            }),
            invalidatesTags: (result, error, {uuid}) => ['Contacts', {type: "Contacts", id: uuid}]
        }),
        postContact: builder.mutation<Contact, ContactQuery>({
            query: (contact) => ({
                url: `contacts`,
                method: "POST",
                body: contact,
            }),
            invalidatesTags: ["Contacts"],
        }),
        addFromBridge: builder.mutation<void, {
            url?: string;
            listUuid?: string;
        }>({
            query: (data) => ({
                url: `contacts/bridge`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Contacts"],
        }),
        deleteContact: builder.mutation<void, ObjectQuery>({
            query: ({uuid}) => ({
                url: `contacts/${uuid}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Contacts"],
        }),
        getContactNotes: builder.query<ContactNote[], ObjectQuery>({
            query: ({uuid}) => `contacts/${uuid}/notes`,
            providesTags: (result, error, {uuid}) => [
                {type: "Contacts", id: uuid}
            ],
        }),
        createContactNote: builder.mutation<ContactNote, ObjectQuery & { note: string, files?: string[] }>({
            query: ({uuid, note, files}) => ({
                url: `contacts/${uuid}/notes`,
                method: "POST",
                body: {note, files},
            }),
            invalidatesTags: (result, error, {uuid}) => [{type: "Contacts", id: uuid}],
        }),
        deleteContactNote: builder.mutation<void, ObjectQuery & { noteUuid: string }>({
            query: ({uuid, noteUuid}) => ({
                url: `contacts/${uuid}/notes/${noteUuid}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, {uuid}) => [{type: "Contacts", id: uuid}],
        }),
        patchContactNote: builder.mutation<ContactNote, ObjectQuery & { noteUuid: string, note: string }>({
            query: ({uuid, noteUuid, note}) => ({
                url: `contacts/${uuid}/notes/${noteUuid}`,
                method: "PATCH",
                body: {note},
            }),
            invalidatesTags: (result, error, {uuid}) => [{type: "Contacts", id: uuid}],
        }),
    }),
});

export const {
    useGetContactsQuery,
    useGetContactQuery,
    usePatchContactMutation,
    usePostContactMutation,
    useDeleteContactMutation,
    useGetContactActivitiesQuery,
    useAutoFillContactMutation,
    useGetContactNotesQuery,
    useCreateContactNoteMutation,
    useDeleteContactNoteMutation,
    usePatchContactNoteMutation,
    useAddFromBridgeMutation,
} = contactApi;
