import {Route, Routes} from "react-router";
import Dashboard from "@/modules/Dashboard";
import Proxy from "@/modules/Proxy";
import Contacts from "@/modules/Contacts";
import Pipelines from "@/modules/Pipelines";
import Feed from "@/modules/Feed";
import NoPage from "@/modules/NoPage";
import Login from "@/modules/Login";
import useErrorMessage from "@/hooks/useErrorMessage";
import ContactPage from "@/modules/contacts/pages/ContactPage";
import User from "@/modules/User";
import Resources from "@/modules/Resources";
import MyPostsPage from "@/modules/feed/pages/MyPostsPage";
import Automation from "@/modules/Automation";
import SingleAutomation from "@/modules/automation/pages/SingleAutomation";
import Privacy from "@/modules/static/pages/Privacy";
import MyQueuePage from "@/modules/feed/pages/MyQueuePage";
import Writer from "@/modules/feed/pages/Writer";
import Medias from "@/modules/Medias";
import Extension from "@/modules/Extension";
import Conversations from "@/modules/Conversations";
import EoySummary from "@/modules/free-tools/EoySummary";
import EoySummaryData from "@/modules/free-tools/EoySummaryData";
import MessageTemplates from "@/modules/conversations/MessageTemplates";

const AppRouter = () => {
    useErrorMessage();

    return (
        <Routes>
            <Route path="/" element={<Dashboard/>}/>

            <Route index path="dashboard" element={<Dashboard/>}/>

            <Route path="proxy/*" element={<Proxy/>}/>

            <Route path="conversations/templates" element={<MessageTemplates/>}/>
            <Route path="conversations/:conversationId?" element={<Conversations/>}/>

            <Route path="automation" element={<Automation/>}/>
            <Route path="automation/:uuid" element={<SingleAutomation/>}/>

            <Route path="lists/:listUuid/contacts" element={<Contacts/>}/>
            <Route path="lists/:listUuid/feed" element={<Feed/>}/>
            <Route path="lists/:listUuid/pipelines" element={<Pipelines/>}/>

            <Route path="contacts" element={<Contacts/>}/>
            <Route path="contacts/:uuid" element={<ContactPage/>}/>

            <Route path="feed/:uuid?" element={<Feed/>}/>

            <Route path="feed/posts/:uuid?" element={<MyPostsPage/>}/>

            <Route path="feed/queue" element={<MyQueuePage/>}/>
            <Route path="feed/writer/:uuid?" element={<Writer/>}/>

            <Route path="resources" element={<Resources/>}/>

            <Route path="login" element={<Login/>}/>

            <Route path="user" element={<User/>}/>
            <Route path="medias" element={<Medias/>}/>

            <Route path="static/privacy" element={<Privacy/>}/>

            <Route path="extension" element={<Extension/>}/>
            <Route path="static/extension" element={<Extension/>}/>

            <Route path="static/tools/eoy-summary" element={<EoySummary/>}/>
            <Route path="static/tools/eoy-summary/:uuid" element={<EoySummaryData/>}/>

            <Route path="*" element={<NoPage/>}/>
        </Routes>
    )
}
export default AppRouter;