import {Label} from "@/components/ui/label";
import {Input} from "@/components/ui/input";
import {Checkbox} from "@/components/ui/checkbox";
import {Button} from "@/components/ui/button";
import Logo from "@/components/elements/Logo";
import {useState} from "react";
import {CheckedState} from "@radix-ui/react-checkbox";

const EoySummary = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [linkedinUrl, setLinkedinUrl] = useState('');
    const [email, setEmail] = useState('');
    const [terms, setTerms] = useState<CheckedState>(false);

    const validateRequest = () => {
        if (!linkedinUrl.includes('linkedin.com/in/')) {
            return false
        }

        // email should be valid
        if (!email.includes('@') || !email.includes('.')) {
            return false
        }

        return terms;
    }

    const handleSubmit = async () => {
        if(!validateRequest()) {
            alert('Please fill in all the required fields with valid data.')
            return
        }

        const backendHost = process.env.REACT_APP_API_HOST
        const url = `${backendHost}/tools/eoy`

        setIsLoading(true)

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    linkedinUrl,
                    email,
                    terms
                })
            })

            if (response.ok) {
                const data = await response.json()

                if(data.uuid) {
                    window.location.href = `/static/tools/eoy-summary/${data.uuid}`
                } else {
                    alert('Something went wrong. Please try again later.')
                }
            } else {
                alert('Something went wrong. Please try again later.')
            }

            setIsLoading(false)
        } catch (error) {
            console.error(error)

            setIsLoading(false)

            alert('Something went wrong. Please try again later.')
        }
    }

    return <div className="p-8 flex flex-col gap-8 h-screen">
        <h1 className="text-4xl font-extrabold font-display">
            Your 2024 LinkedIn Summary
        </h1>

        <div className="flex flex-col gap-4 max-w-lg">
            <div className="flex flex-col gap-2">
                <Label htmlFor="linkedinUrl">
                    Your profile URL
                </Label>
                <Input type="text" placeholder="https://www.linkedin.com/in/your-name-here/" id="linkedinUrl" value={linkedinUrl} onChange={(e) => setLinkedinUrl(e.target.value)} disabled={isLoading}/>
            </div>

            <div className="flex flex-col gap-2">
                <Label htmlFor="linkedinUrl">
                    Your e-mail address
                </Label>
                <Input type="email" placeholder="your@email.com" id="linkedinUrl" value={email} onChange={(e) => setEmail(e.target.value)} disabled={isLoading}/>
            </div>

            <div className="flex flex-col gap-2">
                <Label htmlFor="terms" className="flex items-center gap-2">
                    <Checkbox id="terms" name="terms" checked={terms} onCheckedChange={setTerms} disabled={isLoading}/>
                    I agree to the <a href="/static/privacy" className="underline">terms and conditions</a>
                </Label>
            </div>

            <div className="flex gap-2">
                <Button disabled={isLoading} onClick={handleSubmit} className="w-full">
                    Submit
                </Button>
            </div>
        </div>

        <div className="flex-grow">&nbsp;</div>

        <div className="flex gap-2 text-sm items-center">
            <Logo className="w-auto h-8"/>
            <span className="text-muted-foreground">
                Made with ❤️ by <a href="https://www.warmr.app" className="underline">Warmr</a>.
            </span>
        </div>
    </div>
}

export default EoySummary