import {useLazyGetConversationsQuery} from "@/api/conversation";
import {cn} from "@/lib/utils";
import ConversationListItem from "@/modules/conversations/components/ConversationListItem";
import InfiniteScroll from "@/components/elements/InfiniteScroll";
import React, {useEffect, useRef, useState} from "react";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Button} from "@/components/ui/button";
import NewConversation from "@/modules/conversations/components/NewConversation";
import {Plus} from "lucide-react";

const conversationListCacheKey = 'conversations.list';

const ConversationsList = ({className}: {
    className?: string
}) => {
    const prevCursor = useRef<string>('');
    const nextCursor = useRef<string>('');

    const fullData = useRef<any[]>([]);
    const isLoading = useRef(false);

    const [lazyLoad] = useLazyGetConversationsQuery()
    const handleLazyLoad = () => {
        if (isLoading.current) return;
        isLoading.current = true;

        lazyLoad({
            prevCursor: prevCursor.current,
            nextCursor: nextCursor.current
        }).then((response) => {
            prevCursor.current = ((response?.data as any)?.metadata?.prevCursor ?? prevCursor.current);
            nextCursor.current = ((response?.data as any)?.metadata?.nextCursor ?? nextCursor.current);

            fullData.current = ([
                    ...fullData.current,
                    ...((response?.data as any)?.conversations ?? [])]
                    .filter((conversation: any, index: number, self: any) => self.findIndex((t: any) => t.entityUrn === conversation.entityUrn) === index)
                    .sort((a: any, b: any) => b.lastActivityAt - a.lastActivityAt)
            );

            localStorage.setItem(conversationListCacheKey, JSON.stringify(fullData.current));

            isLoading.current = false;
        });
    }

    const [cachedData, setCachedData] = useState<any[]>([]);

    useEffect(() => {
        const cachedData = localStorage.getItem(conversationListCacheKey);
        if (cachedData) {
            setCachedData(JSON.parse(cachedData));
        }
    }, []);

    const listData = fullData.current && fullData.current.length > 0 ? fullData.current : cachedData;

    return (
        <InfiniteScroll
            className={cn('h-full w-full', "border-r", className)}
            onBottomReached={handleLazyLoad}>

            <div className="w-full flex flex-col items-center justify-center gap-4">
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            className="size-14"
                        >
                            <Plus className="size-6"/>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent
                        sideOffset={8}
                        side="right"
                        align="start"
                        className="w-[600px] bg-tertiary text-tertiary-foreground"
                    >
                        <NewConversation/>
                    </PopoverContent>
                </Popover>

                {listData?.map((conversation: any) => (
                    <ConversationListItem
                        key={conversation.entityUrn}
                        conversation={conversation}
                    />
                ))}

            </div>
        </InfiniteScroll>
    )
}

export default ConversationsList;