import DateRenderer from "@/components/elements/DateRenderer";
import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar";
import {useNavigate, useParams} from "react-router";
import useEncrypt from "@/hooks/useEncrypt";
import {useMemo} from "react";
import {cn} from "@/lib/utils";
import {HoverCard, HoverCardContent, HoverCardTrigger} from "@/components/ui/hover-card";

const ConversationListItem = ({conversation}: any) => {
    const {conversationId} = useParams();
    const {decrypt} = useEncrypt();

    const decryptedConversationFullId = useMemo(() => decrypt(conversationId ?? ''), [conversationId, decrypt]) ?? '';
    const decryptedConversationId = decryptedConversationFullId.split('/')[0];

    const navigate = useNavigate();

    const {encrypt} = useEncrypt();
    const openConversation = () => {
        navigate(`/conversations/${encrypt(`${conversation.entityUrn}/${conversation.participants?.map((member: any) => member.backendUrn).join(',')}`)}`);
    }

    const isActive = decryptedConversationId === (conversation.entityUrn ?? '')

    if (!conversation.participants?.[0]?.lastName) {
        return null;
    }

    return (
        <div className="size-14">
            <HoverCard>
                <HoverCardTrigger>
                    <div className={cn("rounded-full transition-opacity transition-filter", {
                        'grayscale opacity-50 hover:opacity-100': !isActive && conversationId,
                        'cursor-pointer': !isActive,
                        'bg-muted': isActive,
                        'outline outline-4 outline-tertiary': isActive
                    })} onClick={openConversation}>
                        <Avatar className="size-full">
                            {conversation.participants?.[0]?.avatarUrl &&
                                <AvatarImage src={conversation.participants?.[0]?.avatarUrl}/>}
                            <AvatarFallback>{conversation.participants?.[0]?.firstName?.[0]?.toUpperCase()}</AvatarFallback>
                        </Avatar>
                    </div>
                </HoverCardTrigger>
                <HoverCardContent
                    sideOffset={8}
                    side="right"
                >

                    <h2
                        className="font-display text-lg"
                    >
                        {conversation.participants?.[0]?.firstName} {conversation.participants?.[0]?.lastName}
                    </h2>

                    <p className="text-sm opacity-50"><DateRenderer date={conversation.lastActivityAt ?? 0}/></p>
                    <p className="text-sm opacity-50 pt-4 truncate line-clamp-1 w-full overflow-hidden text-ellipsis">{conversation.latestMessage ?? ''}</p>


                </HoverCardContent>
            </HoverCard>
        </div>
    )
}

export default ConversationListItem;