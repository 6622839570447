import {useParams} from "react-router";
import {useLazyGetMessagesQuery} from "@/api/conversation";
import {cn} from "@/lib/utils";
import useEncrypt from "@/hooks/useEncrypt";
import InfiniteScroll from "@/components/elements/InfiniteScroll";
import {useEffect, useMemo, useRef, useState} from "react";
import NewMessage from "@/modules/conversations/components/NewMessage";
import useSocket from "@/hooks/useSocket";
import ConversationMessage from "@/modules/conversations/components/ConversationMessage";
import dayjs from "dayjs";
import {useGetContactsQuery} from "@/api/contact";
import ContactPage from "@/modules/contacts/pages/ContactPage";
import {ScrollArea} from "@/components/ui/scroll-area";
import NoContactPanel from "@/modules/conversations/components/NoContactPanel";

/**
 * Groups messages by date for display
 */
const groupMessagesByDay = (messages: any[]) => {
    return messages.reduce((acc: any[], item: any) => {
        const date = dayjs(item.deliveredAt);
        if (!acc.length) {
            acc.push([item]);
            return acc;
        }
        const lastChunk = acc[acc.length - 1];
        const lastChunkDate = dayjs(lastChunk[lastChunk.length - 1].deliveredAt);

        if (date.isSame(lastChunkDate, 'day')) {
            lastChunk.push(item);
            return acc;
        }
        acc.push([item]);
        return acc;
    }, []);
};

/**
 * Processes and formats message data
 */
const processMessageData = (messages: any[]) => {
    return messages
        .map((item: any) => ({
            ...item,
            deliveredDate: dayjs(item.deliveredAt)
        }))
        .filter((item) => item.entityUrn !== 'urn:li:message:_tmp')
        .filter((item, index, self) =>
            self.findIndex((t) => t.entityUrn === item.entityUrn) === index
        )
        .sort((a, b) => a.deliveredAt - b.deliveredAt);
};

/**
 * Main Conversation component that displays messages and contact information
 */
const Conversation = ({className}: { className?: string; }) => {
    const {conversationId} = useParams();
    const {decrypt} = useEncrypt();
    const conversationCacheKey = 'conversations.messages.' + conversationId;

    // Refs for managing state and scroll behavior
    const lastTimestamp = useRef<string>('');
    const fullData = useRef<any[]>([]);
    const isLoading = useRef(false);
    const infiniteScrollRef = useRef<HTMLDivElement>(null);

    // State and queries
    const [cachedData, setCachedData] = useState<any[]>([]);
    const [lazyLoad] = useLazyGetMessagesQuery();
    const {lastEventData: lastMessageNotification} = useSocket('newMessageNotification', true);

    // Decrypt conversation ID and get contact information
    const decryptedConversationFullId = useMemo(() =>
        decrypt(conversationId ?? '') ?? '', [conversationId, decrypt]);
    const decryptedConversationId = decryptedConversationFullId.split('/')[0];
    const contactObjectUrns = decryptedConversationFullId.split('/')[1]?.split(',');

    // Fetch contacts
    const {data: contactsResponse} = useGetContactsQuery({
        objectUrn: contactObjectUrns?.join(',')
    }, {
        skip: !contactObjectUrns?.length,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    });
    const contacts = contactsResponse?.data ?? [];

    /**
     * Scrolls the conversation to the bottom
     */
    const scrollToBottom = () => {
        const scrollFunction = () => {
            const viewport = infiniteScrollRef.current?.querySelector('[data-radix-scroll-area-viewport]');
            viewport?.scrollTo(0, (infiniteScrollRef.current?.scrollHeight ?? 0) * 1000);
        };
        [0, 10, 50, 100, 500, 1000, 1500].forEach(delay =>
            setTimeout(scrollFunction, delay)
        );
    };

    /**
     * Handles lazy loading of messages when scrolling up
     */
    const handleLazyLoad = async () => {
        if (isLoading.current) return;
        isLoading.current = true;

        const scrollContainer = infiniteScrollRef.current?.querySelector('[data-radix-scroll-area-viewport]');
        const scrollTop = scrollContainer?.scrollTop ?? 0;
        const previousHeight = scrollContainer?.scrollHeight ?? 0;

        const response = await lazyLoad({
            conversationUrn: decryptedConversationId,
            lastTimestamp: lastTimestamp.current
        });

        fullData.current = processMessageData([
            ...fullData.current,
            ...((response?.data as any)?.messages?.elements ?? [])
        ]);

        // Maintain scroll position after loading new messages
        requestAnimationFrame(() => {
            if (scrollContainer) {
                const newHeight = scrollContainer.scrollHeight;
                scrollContainer.scrollTop = scrollTop + (newHeight - previousHeight);
            }
        });

        if (!lastTimestamp.current) {
            scrollToBottom();
        }

        isLoading.current = false;
        lastTimestamp.current = fullData.current?.[0]?.deliveredAt ?? '';
    };

    // Effect hooks for initialization and updates
    useEffect(() => {
        const cachedData = localStorage.getItem(conversationCacheKey);
        if (cachedData) {
            setCachedData(JSON.parse(cachedData));
        }
    }, [conversationCacheKey]);

    useEffect(() => {
        lazyLoad({conversationUrn: decryptedConversationId}).then((response) => {
            fullData.current = processMessageData([
                ...fullData.current,
                ...((response?.data as any)?.messages?.elements ?? [])
            ]);
            localStorage.setItem(conversationCacheKey, JSON.stringify(fullData.current));
            scrollToBottom();
        });
    }, [lastMessageNotification, lazyLoad, decryptedConversationId, conversationCacheKey]);

    useEffect(() => {
        fullData.current = [];
        lastTimestamp.current = '';
        scrollToBottom();
        handleLazyLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationId]);

    if (!conversationId) return null;

    const messagesData = fullData.current.length > 0 ? fullData.current : cachedData;
    const dailyChunks = groupMessagesByDay(messagesData);

    return (
        <div className="flex gap-4 w-full">
            {/* Messages Section */}
            <div className="h-full w-full flex flex-col gap-4 flex-grow flex-shrink" ref={infiniteScrollRef}>
                <InfiniteScroll
                    onTopReached={handleLazyLoad}
                    className={cn("h-full flex flex-col gap-4", className)}
                >
                    <div className="flex flex-col gap-4 p-4 w-full">
                        {dailyChunks.map((chunk: any[], index: number) => (
                            <div key={index} className="flex flex-col gap-0.5 pt-4">
                                <p className="text-xs text-gray-500 text-center py-2 bg-background/70 sticky top-0 backdrop-blur-sm">
                                    {dayjs(chunk[0].deliveredDate).startOf('day').format('LL')}
                                </p>
                                {chunk.map((message: any, messageIndex: number) => (
                                    <ConversationMessage
                                        key={message.entityUrn}
                                        message={message}
                                        showInfo={index >= dailyChunks.length - 1 && messageIndex >= chunk.length - 1}
                                        previousMessage={chunk[messageIndex - 1] ?? null}
                                        nextMessage={chunk[messageIndex + 1] ?? null}
                                        contact={contacts[0] ?? null}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
                <NewMessage
                    conversationId={decryptedConversationId}
                    onSending={(message) => {
                        fullData.current = [...fullData.current, {
                            deliveredAt: Date.now(),
                            deliveredDate: dayjs(Date.now()),
                            entityUrn: 'urn:li:message:_tmp',
                            body: {text: message ?? ''},
                            fromUser: true,
                        }];
                        scrollToBottom();
                    }}
                    contact={contacts[0]}
                />
            </div>

            {/* Contact Information Section */}
            <ScrollArea className={cn("flex flex-col gap-4 min-w-[600px] w-1/3 flex-shrink-0", {
                'border-l': contacts?.length > 0
            })}>
                {contacts?.length > 0 ?
                    contacts.map((contact: any) => (
                        <ContactPage key={contact.uuid} forceContactUuid={contact.uuid}/>
                    )) :
                    <div
                        className="flex flex-col items-center justify-center gap-4 p-8 text-center h-[calc(100vh-4em)] bg-tertiary dark:bg-muted rounded-md mr-4">
                        <NoContactPanel messages={fullData.current}/>
                    </div>
                }
            </ScrollArea>
        </div>
    );
};

export default Conversation;