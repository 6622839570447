import {io} from 'socket.io-client';
import {useEffect, useState} from "react";
import useUser from "@/hooks/useUser";

const backendUrl = (process.env.REACT_APP_API_HOST ?? '')
const socket = io(backendUrl);

const useSocket = (eventName: string, userSpecific = false) => {
    const [lastEventData, setLastEventData] = useState<any>(null);

    const {user} = useUser();

    useEffect(() => {
        socket.on((userSpecific && user?.uuid ? user?.uuid + '.' : '') + eventName, setLastEventData);

        return () => {
            //    socket.disconnect();
        }
    }, [eventName, user, userSpecific])

    return {
        lastEventData
    }
}

export default useSocket;