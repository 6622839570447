import {api} from "@/api/api";
import {MessageTemplate} from "@/api/conversations.types";

export const conversationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getConversations: builder.query<void, {
            prevCursor?: string,
            nextCursor?: string
        }>({
            query: ({
                        prevCursor,
                        nextCursor
                    }) => ({
                url: `/conversations?prevCursor=${prevCursor}&nextCursor=${nextCursor}`,
                method: 'GET'
            }),
            providesTags: ['Conversations']
        }),
        getMessages: builder.query<{
            messages: any[]
        }, {
            conversationUrn: string,
            prevCursor?: string,
            nextCursor?: string,
            lastTimestamp?: string
        }>({
            query: ({conversationUrn, prevCursor, nextCursor, lastTimestamp}) => ({
                url: `/conversations/${conversationUrn}/messages?prevCursor=${prevCursor ?? ''}&nextCursor=${nextCursor ?? ''}&lastTimestamp=${lastTimestamp ?? ''}`,
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{
                type: 'Conversations',
                id: arg.conversationUrn
            }]
        }),
        sendMessage: builder.mutation<void, {
            conversationUrn: string,
            message: string
        }>({
            query: ({conversationUrn, message}) => ({
                url: `/conversations/${conversationUrn}/messages`,
                method: 'POST',
                body: {
                    message
                }
            }),
            invalidatesTags: (result, error, arg) => [{
                type: 'Conversations',
                id: arg.conversationUrn
            }]
        }),
        generateIcebreakers: builder.query<string[], {
            contactUuid: string
        }>({
            query: ({contactUuid}) => ({
                url: `/conversations/icebreakers?contactUuid=${contactUuid}`,
                method: 'GET'
            })
        }),
        addReactionToLinkedInMessage: builder.mutation<void, {
            messageUrn: string,
            emoji: string
        }>({
            query: ({messageUrn, emoji}) => ({
                url: `/conversations/messages/reactions`,
                method: 'POST',
                body: {
                    messageUrn,
                    emoji
                }
            })
        }),
        removeReactionFromLinkedInMessage: builder.mutation<void, {
            messageUrn: string,
            emoji: string
        }>({
            query: ({messageUrn, emoji}) => ({
                url: `/conversations/messages/reactions`,
                method: 'DELETE',
                body: {
                    messageUrn,
                    emoji
                }
            })
        }),
        deleteMessage: builder.mutation<void, {
            messageUrn: string
        }>({
            query: ({messageUrn}) => ({
                url: `/conversations/messages`,
                method: 'DELETE',
                body: {
                    messageUrn
                }
            })
        }),
        getMessageTemplates: builder.query<MessageTemplate[], void>({
            query: () => `conversations/templates`,
            providesTags: ["MessageTemplates"]
        }),
        createMessageTemplate: builder.mutation<MessageTemplate, {
            name: string,
            content: string
        }>({
            query: ({name, content}) => ({
                url: `conversations/templates`,
                method: 'POST',
                body: {
                    name,
                    content
                }
            }),
            invalidatesTags: ["MessageTemplates"]
        }),
        patchMessageTemplate: builder.mutation<MessageTemplate, {
            uuid: string,
            name?: string,
            content?: string
        }>({
            query: ({uuid, ...data}) => ({
                url: `conversations/templates/${uuid}`,
                method: 'PATCH',
                body: data
            }),
            invalidatesTags: ["MessageTemplates"]
        }),
        deleteMessageTemplate: builder.mutation<void, {
            uuid: string
        }>({
            query: ({uuid}) => ({
                url: `conversations/templates/${uuid}`,
                method: 'DELETE'
            }),
            invalidatesTags: ["MessageTemplates"]
        })
    }),
});

export const {
    useGetConversationsQuery,
    useLazyGetConversationsQuery,
    useGetMessagesQuery,
    useLazyGetMessagesQuery,
    useSendMessageMutation,
    useLazyGenerateIcebreakersQuery,
    useAddReactionToLinkedInMessageMutation,
    useRemoveReactionFromLinkedInMessageMutation,
    useDeleteMessageMutation,
    useGetMessageTemplatesQuery,
    useCreateMessageTemplateMutation,
    usePatchMessageTemplateMutation,
    useDeleteMessageTemplateMutation
} = conversationApi;
