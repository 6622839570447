import {Textarea} from "@/components/ui/textarea";
import {Button} from "@/components/ui/button";
import {useGetMessageTemplatesQuery, useLazyGenerateIcebreakersQuery, useSendMessageMutation} from "@/api/conversation";
import React, {useRef, useState} from "react";
import {Contact} from "@/api/contact.types";
import {cn} from "@/lib/utils";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@/components/ui/dropdown-menu";
import {ImageIcon, Send, SunSnow, TextSelect} from "lucide-react";
import InlineLoader from "@/components/elements/InlineLoader";
import ObjectMedias from "@/modules/medias/components/ObjectMedias";
import {Link} from "react-router-dom";

type NewMessageProps = {
    conversationId?: string
    onSent?: () => void
    onSending?: (message?: string) => void
    contact?: Contact;
    className?: string;
    variant?: 'horizontal' | 'vertical' | 'vertical-simple';
    scheme?: 'white' | 'default';
}

const NewMessage = ({
                        conversationId,
                        scheme = 'default',
                        variant = 'horizontal',
                        onSent,
                        onSending,
                        contact,
                        className
                    }: NewMessageProps
) => {
    const [send, {isLoading}] = useSendMessageMutation();
    const [message, setMessage] = useState('');

    const [medias, setMedias] = useState<any[]>([]);

    const [objectMediasOpen, setObjectMediasOpen] = useState<boolean>(false);

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [isIcebreakerLoading, setIsIcebreakerLoading] = useState(false);

    const [getIcebreakers, {
        data: icebreakers,
    }] = useLazyGenerateIcebreakersQuery();
    const canGenerateIcebreaker = !!contact;
    const canGenerateQualityIcebreaker = !!contact?.interests?.length;

    const {data: messageTemplates} = useGetMessageTemplatesQuery();

    const handleSend = () => {
        onSending?.(message);
        if (conversationId)
            send({conversationUrn: conversationId, message}).then(() => {
                setMessage('');
                onSent?.();
                setTimeout(() => textareaRef.current?.focus(), 100);
            })
    }

    return (
        <form
            onMouseEnter={() => textareaRef.current?.focus()}
            onSubmit={(e) => {
                e.preventDefault();
                handleSend();
            }}
            className={cn("flex gap-1", {
                'flex-col': variant.includes('vertical'),
                'h-24': variant.includes('horizontal'),
                'gap-4': variant.includes('simple')
            }, className)}
        >
            <div
                className={cn("w-full relative flex-grow", {
                    'h-24': variant.includes('horizontal'),
                    'h-auto': variant.includes('vertical')
                })}
            >
                <Textarea
                    ref={textareaRef}
                    disabled={isLoading}
                    className={cn("bg-muted p-4 rounded-md border-none resize-none h-full w-full", {
                        'rounded-l-md rounded-r-none': variant.includes('horizontal'),
                        'rounded-t-md rounded-b-none': variant.includes('vertical'),
                        'h-40': variant.includes('vertical'),
                        'rounded-sm': variant.includes('simple'),
                        'bg-white': scheme === 'white',

                    })}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSend();
                        }
                    }}
                />

                <div className="absolute bottom-2 right-2 flex gap-2 items-center">
                    <ObjectMedias
                        medias={medias} setMedias={setMedias} canAdd={true} canAddMultiple={false}
                        addRelevancySearch={message}
                        hadCustomAddButton={true}
                        isOpen={objectMediasOpen}
                        onOpenChange={setObjectMediasOpen}
                        className="hidden"
                    />

                    <Button variant="ghost" className="size-10 p-0" onClick={() => setObjectMediasOpen(true)}>
                        <ImageIcon className={cn("size-6 text-foreground")}/>
                    </Button>

                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant="ghost" className="size-10 p-0"
                            >
                                <TextSelect className={cn("size-6 text-foreground")}/>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {messageTemplates?.map((template: any) => (
                                <DropdownMenuItem
                                    key={template.uuid}
                                    onClick={() => {
                                        setMessage(template.content);
                                    }}
                                    className="w-full flex gap-2 items-center"
                                >
                                    {template.name}
                                </DropdownMenuItem>
                            ))}

                            <DropdownMenuItem asChild>
                                <Link to="/conversations/templates" className="italic">
                                    Manage templates
                                </Link>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>


                    {canGenerateIcebreaker && <DropdownMenu
                        onOpenChange={(isOpen) => {
                            if (isOpen) {
                                setIsIcebreakerLoading(true);
                                getIcebreakers({
                                    contactUuid: contact.uuid
                                }).then((response) => {
                                    setIsIcebreakerLoading(false);
                                });
                            }
                        }}
                    >
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant="ghost" className="size-10 p-0"
                            >
                                <SunSnow className={cn("size-6 text-foreground", {
                                    "opacity-50": !canGenerateQualityIcebreaker,
                                })}/>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {isIcebreakerLoading ? <div className="flex flex-col gap-2">
                                <InlineLoader/>
                            </div> : icebreakers?.map((icebreaker: string) => (
                                <DropdownMenuItem
                                    key={icebreaker}
                                    onClick={() => {
                                        setMessage(icebreaker);
                                    }}
                                    className="w-full flex gap-2 items-center"
                                >
                                    {icebreaker}
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>}
                </div>
            </div>

            <Button
                variant={scheme === 'white' ? 'white' : "tertiary"}
                onClick={handleSend}
                disabled={isLoading || !message.replaceAll(/\s/g, '')}
                className={cn("flex-grow p-0 flex items-center justify-center", {
                    'rounded-r-md rounded-l-none': variant.includes('horizontal'),
                    'rounded-b-md rounded-t-none': variant.includes('vertical'),
                    'w-24 h-full': variant.includes('horizontal'),
                    'w-full': variant.includes('vertical'),
                    'rounded-sm': variant.includes('simple')
                })}
            >
                <Send className="size-6"/>
            </Button>
        </form>
    )
}

export default NewMessage;