import {Key, useEffect, useState} from "react";
import {cn} from "@/lib/utils";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {
    useAddReactionToLinkedInMessageMutation,
    useRemoveReactionFromLinkedInMessageMutation
} from "@/api/conversation";
import {useIconPickerStore} from "@/components/data/ObjectIcon";
import {SearchIcon} from "lucide-react";
import {PopoverClose} from "@radix-ui/react-popover";

type MessageReactionsProps = {
    message: any;
    className?: string;
}

const bubbleClassName = "border bg-background border-muted size-8 flex items-center justify-center rounded-full font-lg";

const reactionEmojis = [
    '👍',
    '👎',
    '🔥',
    '🎉',
    '😍',
    '😂'
]

const lastestEmojisCacheKey = 'emojis.lastest';

const AddReaction = ({messageUrn, onReact}: { messageUrn: string, onReact?: (emoji: string) => void }) => {
    const [addReaction] = useAddReactionToLinkedInMessageMutation();

    const [latestEmojis, setLatestEmojis] = useState<string[]>([]);

    useEffect(() => {
        const existingEmojis = JSON.parse(localStorage.getItem(lastestEmojisCacheKey) ?? '[]');
        setLatestEmojis(existingEmojis);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        open: openEmojiPicker,
        close: closeEmojiPicker,
        setPickerPosition,
        setHandleIconChange
    } = useIconPickerStore((state) => state) as any;

    const pushToLatestEmojisArray = (emojis: string[]) => {
        const existingEmojis = JSON.parse(localStorage.getItem(lastestEmojisCacheKey) ?? '[]');
        existingEmojis.unshift(...emojis);
        if (existingEmojis.length > 3) {
            existingEmojis.pop();
        }
        localStorage.setItem(lastestEmojisCacheKey, JSON.stringify(existingEmojis));
        setLatestEmojis(existingEmojis);
    }

    const handleReactAction = (emoji: string) => {
        addReaction({
            messageUrn,
            emoji
        })

        pushToLatestEmojisArray([emoji]);

        if (onReact) onReact(emoji);
    }

    useEffect(() => {
        if (setHandleIconChange) setHandleIconChange((data: any) => console.log(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reactionEmojisData = [
        ...latestEmojis,
        ...reactionEmojis
    ];

    return (
        <Popover>
            <PopoverTrigger asChild>
                <button
                    className={cn(bubbleClassName, [
                        'cursor-pointer',
                        'hover:bg-muted',
                    ])}
                >
                    +
                </button>
            </PopoverTrigger>
            <PopoverContent
                className="w-auto p-2"
            >
                <div className="flex gap-2">
                    {reactionEmojisData
                        .filter((emoji, index, self) => self.indexOf(emoji) === index)
                        ?.map((emoji, index) => (
                            <PopoverClose
                                key={'emoji' + index}
                            >
                                <button
                                    className={cn([
                                        'block',
                                        'size-10',
                                        'text-lg',
                                        'rounded-full',
                                        'hover:bg-muted'
                                    ])}
                                    onClick={() => handleReactAction(emoji)}
                                >
                                    {emoji}
                                </button>
                            </PopoverClose>
                        ))}

                    <button
                        className={cn([
                            'block',
                            'size-10',
                            'text-lg',
                            'rounded-full',
                            'hover:bg-muted',
                            'flex',
                            'items-center',
                            'justify-center'
                        ])}
                        onClick={(e) => {
                            setPickerPosition({
                                top: e.clientY,
                                left: e.clientX
                            });

                            openEmojiPicker()
                        }}
                    >
                        <SearchIcon className="text-foreground size-5"/>
                    </button>
                </div>
            </PopoverContent>
        </Popover>
    )
}

const MessageReactions = ({message, className}: MessageReactionsProps) => {
    const [addedReaction, setAddedReaction] = useState<string[]>([]);
    const [removedReaction, setRemovedReaction] = useState<string[]>([]);

    const [removeReaction] = useRemoveReactionFromLinkedInMessageMutation();

    const handleReactionRemove = (emoji: string) => {
        removeReaction({
            messageUrn: message.entityUrn,
            emoji
        })

        setAddedReaction((prev) => prev.filter((e) => e !== emoji));
        setRemovedReaction((prev) => [...prev, emoji]);
    }

    const onReact = (emoji: string) => {
        setAddedReaction((prev) => [...prev, emoji]);
        setRemovedReaction((prev) => prev.filter((e) => e !== emoji));
    }

    const reactionsData = message.reactionSummaries?.filter((reactionSummary: {
        emoji: string;
    }) => {
        return !removedReaction.includes(reactionSummary.emoji);
    })

    const hasReactions = reactionsData?.length > 0 || addedReaction?.length > 0;

    return (
        <>
            <div className={cn("flex gap-2", className)}>
                {reactionsData?.map((reactionSummary: {
                    emoji: string;
                    viewerReacted: boolean;
                }, index: Key) => {
                    return <div
                        key={index}
                        className={cn(bubbleClassName, {
                            'cursor-pointer': reactionSummary.viewerReacted,
                            'hover:bg-muted': reactionSummary.viewerReacted
                        })}
                        onClick={reactionSummary.viewerReacted ? () => handleReactionRemove(reactionSummary.emoji) : undefined}
                    >
                        {reactionSummary.emoji}
                    </div>;
                })}

                {addedReaction?.map((emoji: string, index: Key) => {
                    return <div
                        key={index}
                        className={cn(bubbleClassName, {
                            'cursor-pointer': true,
                            'hover:bg-muted': true
                        })}
                        onClick={() => handleReactionRemove(emoji)}
                    >
                        {emoji}
                    </div>;
                })}

                {hasReactions &&
                    <AddReaction onReact={onReact} messageUrn={message.entityUrn}/>
                }
            </div>

            {!hasReactions && <div
                className={cn('h-0 overflow-visible z-10', 'opacity-0 group-hover:opacity-100', className)}
            >
                <AddReaction onReact={onReact} messageUrn={message.entityUrn}/>
            </div>}
        </>
    )
}

export default MessageReactions;