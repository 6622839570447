import {ReactNode, useCallback, useEffect, useRef} from "react";
import Loader from "@/components/elements/Loader";
import {ScrollArea} from "../ui/scroll-area";

type InfiniteScrollProps = {
    children: ReactNode,
    onTopReached?: () => void,
    onBottomReached?: () => void,
    loadOnTopReached?: boolean,
    loadOnBottomReached?: boolean,
    className?: string
}

const InfiniteScroll = ({
                            children,
                            onTopReached,
                            onBottomReached,
                            className
                        }: InfiniteScrollProps) => {
    const scrollAreaRef = useRef<HTMLDivElement>(null);
    const topElement = useRef<HTMLDivElement>(null);
    const bottomElement = useRef<HTMLDivElement>(null);
    const checkInterval = useRef<any>(null);
    const hasExecuted = useRef(false);

    const isInView = useCallback((element: HTMLDivElement) => {
        const rect = element.getBoundingClientRect();
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

        return (
            rect.top >= 0 &&
            rect.top <= viewHeight
        );
    }, []);

    const checkReached = () => {
        if (!hasExecuted.current) {
            if (onTopReached && topElement.current && isInView(topElement.current)) {
                onTopReached();
                hasExecuted.current = true;
            }

            if (onBottomReached && bottomElement.current && isInView(bottomElement.current)) {
                onBottomReached();
                hasExecuted.current = true;
            }
        }
    }

    const handleScroll = useCallback(() => {
        hasExecuted.current = false;
    }, []);

    useEffect(() => {
        scrollAreaRef.current?.querySelector('[data-radix-scroll-area-viewport]')?.addEventListener('scroll', handleScroll);

        checkInterval.current = setInterval(checkReached, 250);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            scrollAreaRef.current?.querySelector('[data-radix-scroll-area-viewport]')?.removeEventListener('scroll', handleScroll);

            clearInterval(checkInterval.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ScrollArea className={className} ref={scrollAreaRef}>
            {onTopReached &&
                <div ref={topElement} className="flex items-center justify-center h-40" onMouseEnter={() => {
                    checkReached();
                }}>
                    <Loader className="my-12"/>
                </div>}

            {children}

            {onBottomReached &&
                <div ref={bottomElement} className="flex items-center justify-center h-40" onMouseEnter={() => {
                    checkReached();
                }}>
                    <Loader className="my-12"/>
                </div>}
        </ScrollArea>
    )
}

export default InfiniteScroll;