import Joyride from 'react-joyride';
import {useEffect, useState} from "react";

const OnboardingTour = () => {
    const [run, setRun] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setRun(false);
        }, 2500);
    }, []);

    const steps = [
        {
            target: 'nav a[href="/contacts"]',
            content: 'You can find your contacts here.'
        }
    ];

    return (
        <Joyride
            run={run}
            steps={steps}
            styles={{
                overlay: {
                    filter: 'blur(10px)'
                },
                buttonClose: {
                    borderRadius: '50%',
                },
                options: {
                    // arrowColor: 'var(--primary-color)',
                    backgroundColor: 'hsl(var(--background))',
                    overlayColor: 'rgba(0, 0, 0, 0.15)',
                    primaryColor: 'hsl(var(--primary))',
                    textColor: 'hsl(var(--foreground))',
                    // width: 900,
                    // zIndex: 1000
                }
            }}
        />
    )
}

export default OnboardingTour;