import {useTranslation} from "react-i18next";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useEffect, useState} from "react";
import {
    useCreateMessageTemplateMutation,
    useDeleteMessageTemplateMutation,
    useGetMessageTemplatesQuery
} from "@/api/conversation";
import {Dialog, DialogContent, DialogHeader} from "@/components/ui/dialog";
import {DialogDescription, DialogTitle} from "@radix-ui/react-dialog";
import CreateFirst from "@/components/elements/CreateFirst";
import {Button} from "@/components/ui/button";
import {useForm} from "react-hook-form";
import {Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {Textarea} from "@/components/ui/textarea";
import {PlusIcon, TrashIcon} from "lucide-react";
import {MessageTemplate} from "@/api/conversations.types";


const formSchema = z.object({
    content: z.string().min(12, {
        message: "Content must be at least 12 characters.",
    }),
})

const MessageTemplateItem = ({template}: { template: MessageTemplate }) => {
    const {t} = useTranslation();
    const [deleteTemplate] = useDeleteMessageTemplateMutation();

    return <div
        className="flex gap-2 p-4 w-full max-w-[600px] rounded-md bg-muted/50 hover:bg-muted"
    >
        <div className="flex-grow">
            <div>
                {template.content?.split("\n")?.map((line: string, index: number) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
        </div>
        <div className="flex gap-2">
            <Button
                variant="ghost"
                onClick={() => {
                    deleteTemplate({uuid: template.uuid}).then();
                }}
            >
                <TrashIcon className="size-4"/>
            </Button>
        </div>
    </div>
}

const MessageTemplates = () => {
    const {t} = useTranslation();

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('conversations.templates.page_title'));
    }, [t, setCrumbs, setTitle]);

    const {data: messageTemplates} = useGetMessageTemplatesQuery();

    const [createTemplate] = useCreateMessageTemplateMutation();

    const [newTemplateOpen, setNewTemplateOpen] = useState(false);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            content: "",
        },
    })

    function onSubmit(values: z.infer<typeof formSchema>) {
        createTemplate({
            name: (values.content.replaceAll(/[^\w\s]/g, '').slice(0, 20) + '...'),
            content: values.content
        }).then((response) => {
            setNewTemplateOpen(false);
            form.reset({
                content: ""
            });
        });
    }

    return <>
        <div
            className="p-4 flex flex-col gap-4 w-full"
        >
            {messageTemplates && messageTemplates.length > 0 &&
                <div className="flex items-center gap-4 w-full justify-end">
                    <Button
                        variant="outline" className="flex itemx-center gap-2"
                        onClick={() => setNewTemplateOpen(true)}
                    >
                        <PlusIcon className="size-6"/>
                        {t('conversations.templates.create_button')}
                    </Button>
                </div>
            }
            {messageTemplates && messageTemplates.length > 0 ? messageTemplates?.map((template) => (
                <MessageTemplateItem
                    template={template}
                    key={template.uuid}
                />
            )) : <div
                className="flex w-full h-[calc(100vh-10em)] items-center justify-center"
            >
                <CreateFirst
                    title={t('conversations.templates.create_first.title')}
                    description={t('conversations.templates.create_first.description')}
                >
                    <Button
                        onClick={() => setNewTemplateOpen(true)}
                        variant="tertiary"
                    >
                        {t('conversations.templates.create_first.create_button')}
                    </Button>
                </CreateFirst></div>}
        </div>

        <Dialog
            open={newTemplateOpen}
            onOpenChange={setNewTemplateOpen}
        >
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {t('conversations.templates.create.title')}
                    </DialogTitle>
                    <DialogDescription>
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                                <FormField
                                    control={form.control}
                                    name="content"
                                    render={({field}) => (
                                        <FormItem>
                                            <FormLabel>
                                                Content
                                            </FormLabel>
                                            <FormControl>
                                                <Textarea placeholder="Hi, how are you?" {...field} />
                                            </FormControl>
                                            <FormDescription>
                                                This is the content of your template.
                                            </FormDescription>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />

                                <Button type="submit">Submit</Button>
                            </form>
                        </Form>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    </>
}

export default MessageTemplates;